import React from "react";
import Layout from "../components/layout";
import Header from "../components/Header";
import Main from "../components/Main";
import Footer from "../components/Footer";
import Animation from "../components/Animation";
import JSONData from "../content/mycontent.json";

class IndexPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isArticleVisible: false,
            timeout: false,
            articleTimeout: false,
            article: "",
            loading: "is-loading",
            width: 0,
            height: 0,
        };
        this.handleOpenArticle = this.handleOpenArticle.bind(this);
        this.handleCloseArticle = this.handleCloseArticle.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.timeoutId = setTimeout(() => {
            this.setState({ loading: "" });
        }, 100);
        document.addEventListener("mousedown", this.handleClickOutside);
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
    }

    componentWillUnmount() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
        document.removeEventListener("mousedown", this.handleClickOutside);
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleOpenArticle(article) {
        this.setState({
            isArticleVisible: !this.state.isArticleVisible,
            article,
        });

        setTimeout(() => {
            this.setState({
                timeout: !this.state.timeout,
            });
        }, 325);

        setTimeout(() => {
            this.setState({
                articleTimeout: !this.state.articleTimeout,
            });
        }, 350);
    }

    handleCloseArticle() {
        this.setState({
            articleTimeout: !this.state.articleTimeout,
        });

        setTimeout(() => {
            this.setState({
                timeout: !this.state.timeout,
            });
        }, 325);

        setTimeout(() => {
            this.setState({
                isArticleVisible: !this.state.isArticleVisible,
                article: "",
            });
        }, 350);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            if (this.state.isArticleVisible) {
                this.handleCloseArticle();
            }
        }
    }

    render() {
        let imageOrCanvas = (
            <div id='bgcanvas'>
                <Animation
                    width={this.state.width}
                    height={this.state.height}
                ></Animation>
            </div>
        );

        if (JSONData.BackgroundURL && JSONData.BackgroundURL !== "") {
            let backgroundURL = "url(" + JSONData.BackgroundURL + ")";
            imageOrCanvas = (
                <div id='bg' style={{ backgroundImage: backgroundURL }}></div>
            );
        }

        return (
            <Layout location={this.props.location}>
                <div
                    className={`body ${this.state.loading} ${
                        this.state.isArticleVisible ? "is-article-visible" : ""
                    }`}
                >
                    <div
                        id='wrapper'
                        onMouseDown={this.speedUp}
                        onMouseUp={this.speedDown}
                        onTouchStart={this.speedUp}
                        onTouchEnd={this.speedDown}
                    >
                        <Header
                            onOpenArticle={this.handleOpenArticle}
                            timeout={this.state.timeout}
                        />
                        <Main
                            isArticleVisible={this.state.isArticleVisible}
                            timeout={this.state.timeout}
                            articleTimeout={this.state.articleTimeout}
                            article={this.state.article}
                            onCloseArticle={this.handleCloseArticle}
                            setWrapperRef={this.setWrapperRef}
                        />
                    </div>

                    {imageOrCanvas}
                    <div
                        style={{
                            position: "absolute",
                            width: "100%",
                            alignItems: "center",
                            bottom: "10px",
                        }}
                    >
                        <Footer timeout={this.state.timeout} />
                    </div>
                </div>
            </Layout>
        );
    }
}

export default IndexPage;
